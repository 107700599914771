<!-- 销售管理-核销 -->

<template>
  <div class="vip-container main-cnt">
    <div class="title">核销列表</div>
    <div class="content">
      <div class="write-title flex justify-between">
        <div class="write-title flex">
          <div v-for="(item, i) in tabBtns" :key="i" class="write-title-text"
            :class="activeIndex == i ? 'active' : 'isActive'" @click="onClickTabs(i)">{{ item.name }}</div>
        </div>

        <div>
          <el-button type="primary" plain round @click="onWriteOffDetails"
            v-if="authData.indexOf('n_2wNqyHs8KSc3JsKHiUfFPKO0zT') != -1 && isShowScan">核销明细</el-button>

          <el-button type="primary" round @click="onVerificationCouponCode"
            v-if="authData.indexOf('n_ytHExxFN7rV6XCMV8okjZ3e4mC9') != -1 && tableData.length > 0">核销券码</el-button>
        </div>
      </div>

      <div class="input-box" v-if="isShowScan">
        <KeyBoard ref="keyBoardRef" @onEnter="onEnter" @determineBtn="onEnter" v-model="couponCode"
          :placeholder="'请输入券码编号或提示顾客出示券码扫描'">
        </KeyBoard>
      </div>

      <div v-else class="table-wp">
        <el-table v-loading="loading" :data="tableData" height="calc(100vh - 320px)" style="width: 100%" stripe
          tooltip-effect="light" @selection-change="handleSelectionChange" :row-key="getRowKeys" ref="tableRef">
          <el-table-column type="selection" :reserve-selection="true" width="55" />
          <el-table-column property="sn" label="券码编号" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column property="name" label="优惠券名称" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column property="num" label="数量" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column property="mobile" label="会员电话" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column property="goods_name" label="关联商品">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.goods_name }}
              </span></template>
          </el-table-column>
          <el-table-column property="goods_stock" label="商品库存" :show-overflow-tooltip="true">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.goods_stock }}
              </span>
            </template>
          </el-table-column>
          <el-table-column property="end_time" label="有效期截止" :show-overflow-tooltip="true"></el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 核销详情 -->
    <w-dialog ref="dialogRef" title="核销明细" width="50%" top="6vh" :modalClick="false" :hideFooter="true">
      <common-table ref="detailsTable" tableHeight="calc(100vh - 325px)" :extraParame="detailsParame"
        :apiName="ScenicApi.getCheckedList" :columns="tableColumns">
      </common-table>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch, nextTick, } from "vue";
  import { ScenicApi } from "@/plugins/api.js";
  import { ElMessage, ElMessageBox } from "element-plus";
  import { useStore } from "vuex";
  import KeyBoard from "@/components/key-board/keyboard.vue";

  const store = useStore();
  const authData = ref([]);  // 权限
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  /**
   *
   * 监听权限
   *
   **/
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  )
  const keyBoardRef = ref(null);  // 输入框对象
  const tabBtns = ref([]);  // 顶部切换数据
  const activeIndex = ref(0);  // tab选中下标
  const couponCode = ref('')  // 输入券码内容
  const isShowScan = ref(true);  // 是否显示输入扫描页面
  const loading = ref(false);  // 是否加载
  const tableData = ref([]);  // 表格数据
  const tableRef = ref(null);  // 表格对象
  const selectData = ref([]);  // 选中数据
  const extraParame = ref({  // 参数
    type: '',
    keywords: '',
  });
  const dialogRef = ref(null);  // 详情对象
  const detailsTable = ref(null);  // 详情表格对象
  const detailsParame = ref({  // 参数
    type: '',
  });
  const tableColumns = ref([  // 表格配置
    {
      prop: "name",
      label: "优惠券名称",
    },
    {
      prop: "sn",
      label: "券码编号",
    },
    {
      prop: "mobile",
      label: "会员手机号",
    },
    {
      prop: "num",
      label: "数量",
    },
    {
      prop: "goods_name",
      label: "关联商品",
    },
    {
      prop: "check_time",
      label: "核销时间",
      minWidth: 120
    },
  ]);
  /**
   * 
   * 获取顶部标签
   * 
   * */
  const getCheckTypeList = () => {
    ScenicApi.getCheckTypeList().then((res) => {
      if (res.Code === 200) {
        tabBtns.value = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 切换顶部标签
   * 
   * */
  const onClickTabs = (i) => {
    couponCode.value = '';
    selectData.value = [];
    tableData.value = [];
    isShowScan.value = true;
    activeIndex.value = i;
    nextTick(() => {
      keyBoardRef.value.serachInputRefFocus();
    });
  }
  /**
   * 
   * 输入完、扫描完按enter键
   * 
   * */
  const onEnter = () => {
    if (!couponCode.value) {
      ElMessage.error('请输入券码编号或提示顾客出示券码扫描');
      return false;
    }
    isShowScan.value = false;
    extraParame.value.type = tabBtns.value[activeIndex.value].id;
    extraParame.value.keywords = couponCode.value;
    getCheckLists();
  }
  /**
   * 
   * 获取列表数据
   * 
   * */
  const getCheckLists = () => {
    loading.value = true;
    selectData.value = [];
    ScenicApi.getCheckLists(extraParame.value).then((res) => {
      loading.value = false;
      if (res.Code === 200) {
        tableData.value = res.Data.list ? res.Data.list : [];
        // 回显表格选中
        if (tableData.value.length > 0) {
          tableData.value.forEach(item => {
            if (item.checked) {
              selectData.value.push(item);
              tableRef.value.toggleRowSelection(item, true);
            }
          })
        }
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 选择表格
   * 
   * */
  const handleSelectionChange = (data) => {
    selectData.value = data;
  }
  const getRowKeys = (row) => {
    return row.sn;
  }
  /**
   * 
   * 核销卷码按钮
   * 
   * */
  const onVerificationCouponCode = () => {
    if (selectData.value.length == 0) {
      ElMessage.error(`请选择核销券码`);
      return false;
    }
    let selectArr = JSON.parse(JSON.stringify(selectData.value));
    ElMessageBox.confirm(
      `是否确认核销！`,
      '核销券码确认',
      { confirmButtonText: '确认', cancelButtonText: '取消', }
    )
      .then(() => {
        let arr = [];
        if (selectArr.length > 0) {
          selectData.value.forEach((item) => {
            arr = arr.concat(item.check_id);
          });
        }
        let params = {
          ids: arr.join(","),
          type: tabBtns.value[activeIndex.value].id,
        };
        // console.log("params", params)
        ScenicApi.confirmWriteOff(params).then((res) => {
          if (res.Code === 200) {
            ElMessage.success(`核销成功`);  // 成功提示
            getCheckLists();
          } else {
            ElMessage.error(res.Message);
          }
        });
      })
      .catch(() => {

      })
  }
  /**
   * 
   * 点击核销明细
   * 
   * */
  const onWriteOffDetails = () => {
    dialogRef.value.show();
    detailsParame.value.type = tabBtns.value[activeIndex.value].id;
    nextTick(() => {
      detailsTable.value.tableLoad();
    });
  }

  onMounted(() => {
    getCheckTypeList();
    nextTick(() => {
      keyBoardRef.value.serachInputRefFocus();
    });
  });
</script>
<style lang="scss">
  .flex {
    display: flex;
  }

  .justify-between {
    justify-content: space-between;
  }

  .text-grey {
    color: var(--text-third-color);
  }

  .el-message-box__header {
    background: var(--menu-bg-color);
    color: var(--text-color);
    font-weight: 700;
  }

  .el-message-box__content {
    border-top: 1px solid var(--menu-bg-color);
    padding: 20px 16px;
  }

  .vip-container {
    font-family: "Source Han Sans CN";

    .content {
      padding: 15px 20px 20px;

      .write-title {
        font-size: 14px;
        margin-bottom: 10px;

        .write-title-text {
          padding: 6px 30px;
          margin-right: 10px;
          border-radius: 5px;
          cursor: pointer;
          line-height: 26px;
        }
      }

      .isActive {
        background: var(--Offline-color);
        color: var(--top-text-color);
      }

      .active {
        background: var(--btn-bg-color);
        color: var(--text-white-color);
      }

      .input-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 700px;

        .el-input__inner {
          height: 60px;
          text-align: center;
        }
      }

      .table-wp {
        .el-table {
          border-radius: 4px 4px 0 0;

          thead {
            color: var(--text-color);
          }

          th.el-table__cell {
            background-color: var(--search-bg-color);
            padding: 9px 0;
            font-size: 15px;
          }

          td.el-table__cell {
            padding: 9px 0;

            .cell {
              >.el-button {
                padding: 4px 0;
                min-height: 20px;
                width: 76px;
                background-color: transparent;
                border-radius: 20px;
                border: none;
                color: var(--theme-color);
                font-size: 14px;
                border: 1px solid var(--theme-color);

                &:hover {
                  /* background-color: var(--theme-color);
                  color: var(--text-white-color); */
                }
              }

              >.img-wp {
                display: inline-block;
                width: 32px !important;
                height: 32px !important;
                border-radius: 2px;
                margin-right: 8px;
                vertical-align: middle;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                .el-image {
                  width: 100% !important;
                  height: 100% !important;
                }

                .remark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.3);
                  pointer-events: none;

                  .dot {
                    padding-left: 5px;
                    line-height: 16px;

                    span {
                      display: inline-block;
                      width: 4px;
                      height: 4px;
                      border-radius: 2px;
                      background-color: var(--text-white-color);
                      margin-right: 4px;
                    }
                  }

                  .num {
                    font-size: 10px;
                    color: var(--text-white-color);
                    text-align: center;
                    line-height: 10px;
                    vertical-align: middle;
                  }
                }
              }

              >.block {
                width: 32px;
                height: 32px;
                background-color: var(--btn-bg-color);
                border-radius: 3px;
                line-height: 32px;
                text-align: center;
                color: var(--text-white-color);
                cursor: pointer;
              }

              >.no-auth-block {
                opacity: 0.6;
                cursor: not-allowed;
              }

              >.preview {
                width: 30px;
                height: 30px;
                line-height: 30px;
                border-radius: 3px;
                background-color: #e8e8e8;
                color: var(--text-gray-color);

                .iconfont {
                  font-size: 12px;
                  margin-left: 6px;
                }
              }

              >.preview.active {
                background-color: var(--search-bg-color);
                color: var(--theme-color);
                cursor: pointer;
              }

              >.qrcode {
                width: 30px;
                height: 30px;
                line-height: 30px;
                border-radius: 3px;
                background-color: var(--search-bg-color);
                color: var(--theme-color);
                cursor: pointer;
                text-align: center;

                .iconfont {
                  font-size: 16px;
                }
              }
            }
          }

          td.el-table__cell.image {
            padding: 5px 0;
          }
        }

        .el-pagination {
          padding: 0;
          margin-top: 15px;
        }

        .el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: var(--search-bg-color);
          color: var(--theme-color);
        }
      }
    }
  }
</style>